<template>
  <input
    v-if="getSiteNames !== undefined"
    placeholder="Filter by Org Name"
    v-model="search"
    class="search-bar"
  />
</template>

<script>
export default {
  name: "Search",
  computed: {
    getSiteNames() {
      return this.$store.getters.getSiteNames;
    },
    search: {
      get() {
        return this.$store.getters.getSearch;
      },
      set(val) {
        this.$store.dispatch("setSearch", val);
        this.$store.dispatch("setSitesPagination", {
          ...this.$store.getters.getSitesPagination,
          currentPage: 1,
        });
        this.$emit("refetch");
      },
    },
  },
};
</script>

<style scoped>
.search-bar {
  border: 2px solid #2c3e50;
  border-radius: 16px;
  padding: 2px 12px;
  transition: 250ms;
}
.search-bar:hover {
  background-color: rgb(238, 238, 238);
}
</style>

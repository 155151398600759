<template>
  <nav id="sidebar" class="sidebar">
    <a href="https://www.touchhalloffame.us" target="_blank">
      <div class="sidebar-header">
        <img
          src="../.././assets/img/WhiteLogo.png"
          alt="rocket alumni solutions logo"
          style="width: 100%; margin-top: -23px; margin-bottom: -20px"
        />
        <div id="email">support@rocketalumnisolutions.com</div>
        <div>Welcome, {{ this.$auth.user.email }}</div>
      </div>
    </a>
    <ul class="list-unstyled components">
      <li>
        <router-link to="/create">Create New Site</router-link>
      </li>
      <!-- <li v-if="this.$store.getters.getUserID == this.getInternalUserID">
        <a href="#menu">{{ "Create New Client Data" }}</a>
      </li> -->
      <li v-if="isInternalAdmin()">
        <router-link to="/createClientData">Create New Blank Data</router-link>
      </li>
      <li v-if="isInternalAdmin()">
        <router-link to="/duplicateClient">Duplicate Existing Data</router-link>
      </li>
      <li v-if="isInternalAdmin()">
        <router-link to="/copyCategory"
          >Copy Category Between Collections</router-link
        >
      </li>
      <li v-if="isInternalAdmin()">
        <router-link to="/scrapeClientCollection">Scrape New Data</router-link>
      </li>
      <li v-if="isInternalAdmin()">
        <router-link to="/addUser">Manage Users & Site Access</router-link>
      </li>
      <li>
        <router-link to="/emailList">Export Email List</router-link>
      </li>
      <li>
        <router-link to="/emailUtils">Email List Utilities</router-link>
      </li>
      <li>
        <router-link to="/exportMarketingSiteInfo"
          >Export Marketing Site Info</router-link
        >
      </li>
      <li>
        <router-link to="/imageUpload">Image Upload</router-link>
      </li>
      <li>
        <button @click.prevent="logout" class="btn">Logout</button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    isInternalAdmin() {
      return this.$auth.getIsSiteAdmin();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin + "/login",
      });
    },
  },
};
</script>

<style>
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

#email {
  margin-top: 10px;
  font-size: 12px;
}
body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.active {
  background-color: #c0c0c0;
}
.active.dropdown-item {
  color: white !important;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
  width: 250px;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #36618c;
  color: #fff;
  transition: all 0.3s;
}
#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #133353;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.781);
  transform: scale(1);
  transition: 0.5s;
}
#sidebar .sidebar-header:hover {
  transform: scale(1.03);
  transition: 0.5s;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.781);
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #133353;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  color: #133353;
  background: #fff;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #fff;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}
a.article,
a.article:hover {
  background: #133353 !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: calc(100% - 250px);
  padding: 40px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
#content.active {
  width: 100%;
}

@media only screen and (max-height: 550px) {
  #sidebar ul.components {
    background-color: #36618c;
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import { PAGE_SIZE } from "@/utils/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientCollectionIDs: undefined,
    sites: undefined,
    siteNames: undefined,
    search: "",
    isActivated: null,
    sitesPagination: {
      currentPage: 1,
      pageSize: PAGE_SIZE
    },
    sortBy: {
      sortBy: "activationDate",
      sortOrder: "desc"
    }
  },
  mutations: {
    setUserID(state, payload) {
      state.userID = payload;
    },
    setClientCollectionIDs(state, payload) {
      state.clientCollectionIDs = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setSiteNames(state, payload) {
      state.siteNames = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setSitesPagination(state, payload) {
      state.sitesPagination = payload;
    },
    setIsActivated(state, payload) {
      state.isActivated = payload;
    },
    setSortBy(state, payload) {
      state.sortBy = payload;
    }
  },
  actions: {
    logout() {
      this.$auth.logout({});
    },
    setClientCollectionIDs({ commit }, payload) {
      commit("setClientCollectionIDs", payload);
    },
    setSites({ commit }, payload) {
      commit("setSites", payload);
    },
    setSiteNames({ commit }, payload) {
      commit("setSiteNames", payload);
    },
    setSearch({ commit }, payload) {
      commit("setSearch", payload);
    },
    setIsActivated({ commit }, payload) {
      commit("setIsActivated", payload);
    },
    setSitesPagination({ commit }, payload) {
      commit("setSitesPagination", payload);
    },
    setSortBy({ commit }, payload) {
      commit("setSortBy", payload);
    }
  },
  modules: {},
  getters: {
    getUserID: state => state.userID,
    getClientCollectionIDs: state => state.clientCollectionIDs,
    getSites: state => state.sites,
    getSiteNames: state => state.siteNames,
    getSearch: state => state.search,
    getSitesPagination: state => state.sitesPagination,
    getIsActivated: state => state.isActivated,
    getSortBy: state => state.sortBy
  }
});

export default {
  methods: {
    sortSites() {
      let sortedSites = this.$store.getters.getSites;
      // sort by categoryCount or entryCount, either by ascending or descending order depending on what user selected
      sortedSites.sort((a, b) => {
        switch (this.keyToSortBy) {
          case "rating": {
            const ratingA = this.siteRating(a).issuesCount;
            const ratingB = this.siteRating(b).issuesCount;
            return this.sortingOrder === "asc"
              ? ratingA - ratingB
              : ratingB - ratingA;
          }
          case "documentCount": {
            const documentCountA = a.documentCount;
            const documentCountB = b.documentCount;
            return this.sortingOrder === "desc"
              ? documentCountA - documentCountB
              : documentCountB - documentCountA;
          }
        }
      });
      return sortedSites;
    },
  },
};

<template>
  <div class="pagination-container" v-if="getSites !== undefined">
    <div style="padding: 8px">
      {{ !!getSites.length ? start : 1 }} - {{ end }} of
      {{ $store.getters.getSitesPagination.totalItems }}
    </div>
    <button
      class="chevron-button"
      title="Previous Page"
      :disabled="currentPage === 1"
      @click="prevPage"
    >
      <b-icon-chevron-left />
    </button>
    <button
      class="chevron-button"
      title="Next Page"
      :disabled="onLastPage"
      @click="nextPage"
    >
      <b-icon-chevron-right />
    </button>
  </div>
</template>

<script>
import { PAGE_SIZE } from "@/utils/constants";

export default {
  name: "Pagination",
  computed: {
    currentPage() {
      return this.$store.getters.getSitesPagination.currentPage;
    },
    getSites() {
      return this.$store.getters.getSites;
    },
    onLastPage() {
      return (
        this.$store.getters.getSitesPagination.currentPage >=
        this.$store.getters.getSitesPagination.totalPages
      );
    },
    start() {
      return Math.max(1, this.currentPage * PAGE_SIZE - PAGE_SIZE + 1);
    },
    end() {
      return Math.min(
        this.$store.getters.getSitesPagination.totalItems,
        this.currentPage * PAGE_SIZE
      );
    }
  },
  methods: {
    async nextPage() {
      await this.$store.dispatch("setSitesPagination", {
        ...this.$store.getters.getSitesPagination,
        currentPage: this.currentPage + 1
      });
      this.$emit("refetch");
    },
    async prevPage() {
      await this.$store.dispatch("setSitesPagination", {
        ...this.$store.getters.getSitesPagination,
        currentPage: this.currentPage - 1
      });
      this.$emit("refetch");
    }
  }
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  justify-content: start;
  align-items: center;
}
.chevron-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transition: 250ms;
}
.chevron-button:not(:disabled):hover {
  background-color: rgb(238, 238, 238);
}
</style>

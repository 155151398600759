export const PAGE_SIZE = 50;

export const FE_ONLY_FILTERS = ["rating", "documentCount"];

export const customerSuccessManagers = ["Mitch", "Nick", "Kevin", "Caleb"];

export const activationDateOptions = [
  {
    key: "ascendingActivationDate",
    label: "Most Recent",
    sortKey: "activationDate",
    order: "desc"
  },
  {
    key: "descendingActivationDate",
    label: "Least Recent",
    sortKey: "activationDate",
    order: "asc"
  }
];

export const lastLoginOptions = [
  {
    key: "ascendingLastLogin",
    label: "Most Recent",
    sortKey: "lastLoginTime",
    order: "desc"
  },
  {
    key: "descendingLastLogin",
    label: "Least Recent",
    sortKey: "lastLoginTime",
    order: "asc"
  }
];

export const lastTouchscreenHeartbeatOptions = [
  {
    key: "ascendingLastTouchscreenHeartbeatTimestamp",
    label: "Most Recent",
    sortKey: "lastTouchscreenHeartbeat.timestamp",
    order: "desc"
  },
  {
    key: "descendingLastTouchscreenHeartbeatTimestamp",
    label: "Least Recent",
    sortKey: "lastTouchscreenHeartbeat.timestamp",
    order: "asc"
  }
];

export const siteFullscreenOptions = [
  {
    key: "ascendingLastTouchscreenHeartbeatFullscreen",
    label: "Fullscreen",
    sortKey: "lastTouchscreenHeartbeat.fullscreen",
    order: "desc"
  },
  {
    key: "descendingLastTouchscreenHeartbeatFullscreen",
    label: "Not Fullscreen",
    sortKey: "lastTouchscreenHeartbeat.fullscreen",
    order: "asc"
  }
];

export const documentCountOptions = [
  {
    key: "ascendingDocuments",
    label: "Lowest to Highest",
    sortKey: "documentCount",
    order: "desc"
  },
  {
    key: "descendingDocuments",
    label: "Highest to Lowest",
    sortKey: "documentCount",
    order: "asc"
  }
];

export const siteOwnerOptions = [
  {
    key: "ascendingSiteOwner",
    label: "Name: A-Z",
    sortKey: "csDetails.siteOwner",
    order: "asc"
  },
  {
    key: "descendingSiteOwner",
    label: "Name: Z-A",
    sortKey: "csDetails.siteOwner",
    order: "desc"
  }
];

export const siteRatingOptions = [
  {
    key: "ascendingRating",
    label: "Lowest to Highest",
    sortKey: "rating",
    order: "asc"
  },
  {
    key: "descendingRating",
    label: "Highest to Lowest",
    sortKey: "rating",
    order: "desc"
  }
];

export const needsAttentionOptions = [
  {
    key: "csAlertTrue",
    label: "Yes",
    sortKey: "csDetails.csAlert",
    order: "desc"
  },
  {
    key: "csAlertFalse",
    label: "No",
    sortKey: "csDetails.csAlert",
    order: "asc"
  }
];

export const installationMediaOptions = [
  {
    key: "installationMedia",
    label: "Has Media",
    sortKey: "csDetails.installationMedia",
    order: "desc"
  },
  {
    key: "noInstallationMedia",
    label: "No Media",
    sortKey: "csDetails.installationMedia",
    order: "asc"
  }
];
